'use client';

import { captureException } from '@sentry/nextjs';
import { FC, useEffect } from 'react';

import { BADGE } from 'src/general/components/BadgeIllustration/BadgeIllustration.type';
import { WholePageMessage } from 'src/general/sections/WholePageMessage/WholePageMessage';
import { ErrorPageProps } from 'src/types/appRouter.types';

const GlobalError: FC<ErrorPageProps> = ({ error, reset }) => {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <WholePageMessage
          buttonLabel="Try again"
          buttonOnClick={reset}
          title="Oops, looks like something went wrong"
          iconSrc={BADGE.CARMA_TOUCH}
          fullHeight
        />
      </body>
    </html>
  );
};

export default GlobalError;
